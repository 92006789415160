import {BrowserModule} from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreLibModule, MooThemeModule, SharedModule} from '@moo/core';
import {EventsModule} from '@moo/events';
import {GradesModule} from '@moo/grades';
import {ParticipantsModule} from '@moo/participants';
import {AuthenticationModule} from '@moo/login';
import {DashboardModule} from '@moo/dashboard';
import {MenuModule} from '@moo/menu';
import {HeaderDirective} from './directives/header.directive';
import {FooterDirective} from './directives/footer.directive';
import {BootstrapModule} from './bootstrap.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ProfileModule} from '@moo/profile';
import {SocialModule} from '@moo/social';
import {SubjectModule} from '@moo/subjects';
import {AgmCoreModule} from '@agm/core';
import {MyclassesModule} from '@moo/myclasses';
import {AnnouncementsModule} from '@moo/announcements';
import {environment} from '../environments/environment';
import { AttendanceModule } from '@moo/attendance';
import { NotificationModule } from '@moo/notifications';
import { FAQModule } from '@moo/faq';
import { NewsModule } from '@moo/news';
import {ChartsModule} from 'ng2-charts';
import { MyservicesModule } from '@moo/myservices';
import { MooDocumentsModule } from '@moo/documents';
import {BannerModule} from '@moo/banner';
import {OwlModule} from 'ngx-owl-carousel';
import { MooTermsConditionsModule } from '@moo/termsconditions';
import { FinanceModule } from '@moo/finance';
import {SettingsModule} from '@moo/settings';
import {AcademiccalendarModule} from '@moo/academiccalendar';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NotFoundComponent} from './not-found/not-found.component';
import { TranscriptModule } from '@moo/transcript';
import { MooPollsModule } from '@moo/polls';


export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderDirective,
    FooterDirective,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BootstrapModule,
    SharedModule,
    AppRoutingModule,
    CoreLibModule.forRoot({apiUrl: environment.apiUrl, lang: 'lang'}),
    MooThemeModule,
    AuthenticationModule,
    EventsModule,
    AnnouncementsModule,
    GradesModule,
    ParticipantsModule,
    DashboardModule,
    MenuModule,
    ProfileModule,
    SocialModule,
    SubjectModule,
    MyclassesModule,
    AttendanceModule,
    BannerModule,
    SettingsModule,
    NewsModule,
    NotificationModule,
    MyservicesModule,
    MooDocumentsModule,
    ChartsModule,
    OwlModule,
    MooTermsConditionsModule,
    FinanceModule,
    SettingsModule,
    FAQModule,
    AcademiccalendarModule,
    BrowserAnimationsModule,
    TranscriptModule,
    MooPollsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBLhEcCQT3g7GqiVoRgy14V03dF4icW6oI'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
