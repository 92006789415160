import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationRoutingModule} from '@moo/login';
import {EventsRoutingModule} from '@moo/events';
import {GradesRoutingModule} from '@moo/grades';
import {ParticipantsRoutingModule} from '@moo/participants';
import {DashboardRoutingModule} from '@moo/dashboard';
import {ProfileRoutingModule} from '@moo/profile';
import {SubjectRoutingModule} from '@moo/subjects';
import {AnnouncementsRoutingModule} from '@moo/announcements';
import {AttendanceRoutingModule} from '@moo/attendance';
import {NewsRoutingModule} from '@moo/news';
import {MyservicesRoutingModule} from '@moo/myservices';
import {BannerRoutingModule} from '@moo/banner';
import { MooTermsconditionsModuleRoutingModule } from '@moo/termsconditions';
import { FinanceRoutingModule } from '@moo/finance';
import {CoreRoutingModule} from '@moo/core';
import {NotificationsRoutingModule} from '@moo/notifications';
import { SettingsRoutingModule } from '@moo/settings';
import { FAQRoutingModule } from '@moo/faq';
import { AcademiccalendarRoutingModule } from '@moo/academiccalendar';
import { MooDocumentsRoutingModule } from '@moo/documents';
import {NotFoundComponent} from './not-found/not-found.component';
import { TranscriptRoutingModule } from '@moo/transcript';

const routes: Routes = [
  {path: '**', component: NotFoundComponent}, // THIS MUST BE THE LAST ROUTE
];

@NgModule({
  imports: [
    CoreRoutingModule,
    AuthenticationRoutingModule,
    DashboardRoutingModule,
    ProfileRoutingModule,
    SubjectRoutingModule,
    SettingsRoutingModule,
    EventsRoutingModule,
    AnnouncementsRoutingModule,
    GradesRoutingModule,
    ParticipantsRoutingModule,
    AttendanceRoutingModule,
    NewsRoutingModule,
    FAQRoutingModule,
    NotificationsRoutingModule,
    MyservicesRoutingModule,
    MooDocumentsRoutingModule,
    BannerRoutingModule,
    MooTermsconditionsModuleRoutingModule,
    FinanceRoutingModule,
    SettingsRoutingModule,
    AcademiccalendarRoutingModule,
    TranscriptRoutingModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
