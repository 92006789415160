<div class="main-container">
  <header>
    <ng-template appHeader></ng-template>
  </header>

  <section>
    <router-outlet></router-outlet>
  </section>

  <ngx-ui-loader bgsColor="{{'inst_1' | mooColor}}" fgsColor="{{'inst_1' | mooColor}}"
               bgsPosition="center-center" fgsPosition="center-center" bgsSize="80" fgsSize="80"
               bgsType="ball-spin-clockwise" fgsType="ball-spin-clockwise" bgsOpacity="1"></ngx-ui-loader>


<ngx-ui-loader [loaderId]="'loader-01'" fgsColor="{{'inst_1' | mooColor}}" fgsPosition="center-center" fgsSize="80"
               fgsType="ball-spin-clockwise"></ngx-ui-loader>

  <footer>
    <div class="footer">
      <ng-template appFooter></ng-template>
    </div>
  </footer>

</div>
