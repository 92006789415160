import {Component, Injector, OnInit, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import {NavigationEnd, Router, NavigationStart, RoutesRecognized} from '@angular/router';
import {MooBaseController, ConfigurationService, MooRouter, MooLocalization, MooSession,
  MooAnalytics, MooThemeService, MooAnalyticsNavigationEvent} from '@moo/core';
import {FooterDirective} from './directives/footer.directive';
import {HeaderDirective} from './directives/header.directive';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title;

  @ViewChild(FooterDirective, {static: true}) footerDirective: FooterDirective;
  @ViewChild(HeaderDirective, {static: true}) headerDirective: HeaderDirective;

  private authListenerSubs: Subscription;
  userIsAuthenticated = false;
  startupDataLoaded = false;
  langs;

  constructor(private configurationService: ConfigurationService, private mooRouter: MooRouter,
              public locale: MooLocalization, private mooSession: MooSession, private router: Router,
              private analytics: MooAnalytics, public translate: TranslateService, private injector: Injector,
              private titleService: Title) {


    // Set Lang for the application
    this.locale.setAppLang();
    // Init GA
    // this.analytics.initGA();

    // Send Analytics
    // this.callAnalytics();

    this.title = this.configurationService.getAppName();
    this.titleService.setTitle(this.title);
  }

  changeLang(lang: any) {
    this.locale.changeLang(lang);
  }

  callAnalytics() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const navEvents: MooAnalyticsNavigationEvent = {url: event.urlAfterRedirects};
        this.analytics.sendNavigationEvent(navEvents);
      }
    });
  }


  ngOnInit(): void {
    this.translate.get('profile_widget_welcome').subscribe((res: string) => {
      console.log('@@@@@ profile_widget_welcome: ', res);
    });

    this.userIsAuthenticated = this.mooSession.getIsAuth();
    this.authListenerSubs = this.mooSession.getSessionListener().subscribe(isAuthenticated => {
      this.userIsAuthenticated = isAuthenticated;
    });
    // this.buildWidgets();
    this.mooSession.getTermsListener().subscribe(isTermsSelected => {
      if (isTermsSelected === true) {
        this.buildWidgets();
      }
    });

    this.setLangs();
  }

  private buildWidgets() {
    const menuId = 'menu';
    if (menuId) {
      const moduleConf = this.configurationService.getModule(menuId);
      const menuFactory = this.injector.get(moduleConf.clazz);
      const menuController = menuFactory(menuId) as MooBaseController;
      menuController.loadWidget(this.footerDirective.viewContainerRef, 'footer');
      menuController.loadWidget(this.headerDirective.viewContainerRef, 'header');
    }
  }

  setLangs() {
    this.langs = this.locale.getLangs();
  }
}
